import { lerp } from '@kaliber/math'
import { track } from '/machinery/track'

/**
 * @param {{ name:string, visible:boolean }} params
 */
export function useVideoTracking({ name, visible }) {
  /** @type {React.MutableRefObject<{ name:string, visible?: boolean, duration?: number, progress?: number }>} */
  const videoMetadataRef = React.useRef({ name })

  /* eslint-disable react-hooks/exhaustive-deps */
  const trackEvent = useEvent((event, additionalVideoMetadata = undefined) => {
    track({
      event,
      metadata: {
        media: {
          type: 'video',
          visible,
          title: name,
          action: event,
          ...videoMetadataRef.current,
          ...additionalVideoMetadata
        }
      }
    })
  })
  return {
    eventhandlers: {
      onPlay: useEvent(() => trackEvent('media_play')),
      onPause: useEvent(() => trackEvent('media_paused')),
      onSeek: useEvent(() => trackEvent('media_seek')),
      onDuration: useEvent(duration => {
        videoMetadataRef.current = { ...videoMetadataRef.current, duration }
      }),
      onProgress: useEvent(({ played }) => {
        const progress = 20 * Math.round(lerp({ start: 0, end: 5, input: played, clamp: true }))
        if (!progress) return
        const progressChanged = progress !== videoMetadataRef.current.progress
        if (!progressChanged) return

        videoMetadataRef.current = { ...videoMetadataRef.current, progress }
        trackEvent('media_progress')
      }),
    },
    trackEvent,
  }
  /* eslint-enable react-hooks/exhaustive-deps */
}

function useEvent(fn) {
  const fnRef = React.useRef(null)
  fnRef.current = fn

  return React.useCallback((...args) => fnRef.current(...args), [])
}
