import ReactPlayerBase from 'react-player/lazy'
import { useTransition, animated } from '@react-spring/web'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { ImageCover } from '/features/buildingBlocks/Image'
import { useVideoTracking } from '/machinery/tracking/useVideoTracking'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import styles from './Video.css'

export function VideoVimeo({ url, title }) {
  const playerConfig = {
    vimeo: {
      playerOptions: {
        controls: true,
        playsinline: true,
        title: false,
      },
    },
  }

  return <VideoBase {...{ url, title, playerConfig }} />
}

function VideoBase({ url, poster = undefined, title, playerConfig }) {
  //TODO DIGP-181 add custom poster to front-end (make poster clickable, and make clear that there is a video behind the poster)
  const [playing, setPlaying] = React.useState(false)
  const isMounted = useRenderOnMount()
  const { ref: isInViewportRef, isInViewport } = useIsInViewport()
  const posterTransitions = useTransition(!playing, {
    initial: { opacity: 1 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })
  const {
    eventhandlers: {
      onPlay: onPlayTracking,
      onPause: onPauseTracking,
      ...eventhandlers
    }
  } = useVideoTracking({ name: title, visible: isInViewport })

  React.useEffect(
    () => {
      if (playing) setPlaying(!isInViewport)
    },
    [ playing, isInViewport ]
  )


  return (
    <div className={styles.componentBase} ref={isInViewportRef}>
      { isMounted && <ReactPlayerBase
        className={styles.reactPlayer}
        controls
        width='100%'
        height='100%'
        config={playerConfig}
        onPlay={handlePlay}
        onPause={handlePause}
        {...eventhandlers}
        {...{ url }}
      />}

      {poster && posterTransitions((spring, show) => (
        show && <Poster layoutClassName={styles.poster} image={poster} {...{ spring }} />
      ))}
    </div>
  )
  function handlePlay(e) {
    setPlaying(true)
    onPlayTracking(e)
  }
  function handlePause(e) {
    setPlaying(false)
    onPauseTracking(e)
  }
}

function Poster({ image, spring, layoutClassName } ) {
  return (
    <animated.div style={spring} className={layoutClassName}>
      <ImageCover
        aspectRatio={16 / 9}
        {...{ image }}
        layoutClassName={styles.image}
      />
    </animated.div>
  )
}
